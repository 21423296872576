import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import menu from "../assets/menu.png"
import { Link, Element } from 'react-scroll'
import Data from '../source/data.json'

const Home = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDesc = data.site.siteMetadata.description
  const assets = 'https://assets.mingjaya.com/images'
  const [items, setItems] = useState();

  useEffect(() => {
      setItems(Data)
  }, [])

  const settings = {
    className: 'slider-brand',
    speed: 500,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    rows: 2,
    arrows: true,
    dots: false,
  };

  const store = {
    className: 'slider-store lg:pl-1.5',
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    dots: true,
    appendDots: dots => (
      <div>
        <div className="slick-dotsmj">
          <ul> {dots} </ul>
        </div>
      </div>
    ), 
  };

  const label = (data) => {
    let label = {}
    if (data === "1") {
      label = {
        classLabel: "new",
        textLabel: "New"
      }
    } else if (data === "2") {
      label = {
        classLabel: "best",
        textLabel: "Best"
      }
    } else if (data === "3") {
      label = {
        classLabel: "hot",
        textLabel: "Hot"
      }
    }
    return label
  }

  const handleOnClick = () => {
    document.getElementById("burger").checked = false;
  };

  return ( 
    <Layout data={items}>
      <SEO title={siteTitle} description={siteDesc} />
      {items ? 
      <>
        <div>
          <nav className="global-sticky-nav py-4 border-btm-header">
            <div className="wrap-center">
              <Link to="/" className="logo-landing-page cursor-pointer">
                <img className="logo-landing" src={items.header.logo} width="156" height="36" alt="Logo Ming Jaya" />
              </Link>
              <input id="burger" type="checkbox" className="invisible absolute" />
              <label htmlFor="burger">
                <img src={menu} alt="Ming Jaya Sejahtera" className="lg:hidden"/>
              </label>
              <div className="menus text-white opacity-87">
                {items.header.data.map((header, i) => {
                  return (
                    <li key={i} className="list-none cursor-pointer">
                      <Link activeClass="active" className="shadow-none" to={header.link} onClick={handleOnClick} spy={true} smooth={true} duration={10}>{header.title}</Link>
                    </li>
                    )
                  })
                }
              </div>
            </div>
          </nav>
        </div>
        <Element name="beranda" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <div className="main-banner relative bg-no-repeat bg-cover bg-center flex items-center overflow-x-hidden" style={{backgroundImage: `url(${assets}/bg-banner-new.webp?v=2)`}}>
            <div className="main-banner-content text-white w-text-main-banner pt-20 pb-0 pl-4 lg:py-0 lg:pl-0">
              <p className="second-orange text-2xl lg:text-4xl font-bold main-font">The Number 1 Online Fashion Store in Indonesia </p>
              <span className="block pt-4 text-sm lg:text-base">Kami selalu berkomitmen memberikan pelayanan terbaik dalam menyediakan produk fashion dan lifestyle yang berkualitas.</span>
              <span className="block pt-4 text-sm lg:text-base">Kenyamanan, keamanan, dan kepuasan customer menjadi energi kami untuk terus menempatkan customer sebagai prioritas utama.</span>
            </div>
          </div>
          <div className="wrap-center overflow-x-hidden">
            <div className="text-white relative mt-14 mb-3">
              <div className="block lg:flex text-white justify-center w-1/2 lg:w-6/12 m-auto space-x-0 lg:space-x-4">
                <div className="text-center w-full my-10 lg:my-0">
                  <h3 className="second-orange f-55 font-bold pb-3">250%</h3>
                  <span className="f-13 block">Pertumbuhan member website per tahun</span>
                </div>
                <div className="text-center w-full my-10 lg:my-0">
                  <h3 className="second-orange f-55 font-bold pb-3">99+</h3>
                  <span className="f-13 block">Brand jam tangan dan luxury fashion ternama</span>
                </div>
                <div className="text-center w-full my-10 lg:my-0">
                  <h3 className="second-orange f-55 font-bold pb-3">96%</h3>
                  <span className="f-13 block">Customer puas berbelanja online</span>
                </div>
              </div>
            </div> 
          </div>
        </Element>
        <Element name="tentang-kami" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <br className="hidden lg:block" />
          <div  className="overflow-x-hidden about-us relative bg-no-repeat bg-cover flex items-center bg-center bg-fixed mt-14 xl:mt-0" style={{backgroundImage: `url(${assets}/bg-about-us-new.webp)`}}>
            <div className="wrap-center text-center relative lg:bottom-0 py-20 lg:pt-20 lg:pb-52">
              <p className="font-bold pb-6 second-orange text-2xl">Tentang Kami</p>
              <span className="text-white text-base w-800 block m-auto">PT Ming Jaya Sejahtera bergerak di bidang industri retail, khususnya produk fashion dan lifestyle. Kami menawarkan produk-produk dari brand ternama dan menjamin autentisitasnya. Kami bertujuan untuk memberikan kemudahan, kenyamanan, dan pengalaman berbelanja bagi para konsumen kami melalui online platform dan retail store yang berada di kota-kota besar di Indonesia.</span>
            </div>
          </div>
        </Element>
        <Element name="keuntungan-belanja" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <br className="hidden lg:block" />
          <div className="wrap-center-full text-center relative text-white pb-4 lg:pb-24">
            <h3 className="second-orange text-2xl font-bold text-center my-10 mx-4">{items.benefit.title}</h3>
            <div className="flex space-x-6 overflow-auto lg:justify-center lg:overflow-hidden lg:inline">
              {items.benefit.data.map((benefit, i) => {
                return (
                  <div key={i} className="content-list">
                    <img src={benefit.img} alt={benefit.title} width="150" height="150"/>
                    <p className="second-orange font-bold text-base mb-3">{benefit.title}</p>
                  </div>
                  )
                })
              }
            </div>
          </div>
        </Element>
        <Element name="bisnis-kami" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <br className="hidden lg:block" />
          <div className="overflow-x-hidden wrap-center-full relative">
            <h3 className="second-orange text-2xl font-bold text-center mx-4 mb-6">Bisnis Kami</h3>
            <br />
            <br className="hidden lg:block" />
            <div className="wrap-center block lg:flex lg:items-center lg:justify-center">
              <div className="w-full">
                <img className="w-img-bussiness float-left relative" alt='Produk-Produk Mingjaya' src={`${assets}/img-jamtangan.webp`} />
              </div>
              <div className="w-full py-8 lg:py-0">
                <p className="second-orange text-xl font-bold mb-6">Jamtangan.com</p>
                <span className="block text-white text-sm lg:text-base pb-10">Jamtangan.com merupakan platform penjualan berbasis online dan offline yang fokus menjual produk jam tangan. Jamtangan.com berkomitmen untuk menyediakan jam tangan dengan pilihan lengkap serta memberikan pelayanan dan harga terbaik untuk setiap pelanggan.
                <br /><br />Dimulai dari bisnis jual-beli jam tangan di lingkup pasar yang terbatas, saat ini jamtangan.com adalah platform penjualan produk jam tangan terbesar di Indonesia. </span>
                <a href="https://www.jamtangan.com/" target="_blank" rel="noreferrer" className="rounded p-4 text-xs leading-5 text-black btn-sec-orange font-bold outline-none flex justify-center lg:inline opacity-90 hover:opacity-100">MULAI BELANJA</a>
              </div>
            </div>
            <br />
            <br className="hidden lg:block" />
            <div className="wrap-center block lg:flex lg:flex-row-reverse lg:items-center lg:justify-center">
              <div className="w-full">
                <img className="w-img-bussiness lg:float-right relative" alt='Produk-Produk Mingjaya' src={`${assets}/img-voila.webp`} />
              </div>
              <div className="w-full py-8 lg:py-0">
                <p className="second-orange text-xl font-bold mb-6">voilà.id</p>
                <span className="block text-white text-sm lg:text-base pb-10">Berawal dari misi untuk mendirikan platform belanja produk mewah dengan jaminan 100% autentik, voilà.id hadir untuk memenuhi kebutuhan para luxury fashion enthusiast.
                <br />< br/>Berdiri di tahun 2020, voilà.id menawarkan koleksi tas, sepatu, pakaian, dan aksesoris dari desainer ternama dunia. Hingga saat ini, voilà.id  menjual 150+ brand luxury dari seluruh dunia.</span>
                <a href="https://voila.id/" target="_blank" rel="noreferrer" className="rounded p-4 text-xs leading-5 text-black btn-sec-orange font-bold outline-none flex justify-center lg:inline opacity-90 hover:opacity-100">MULAI BELANJA</a>
              </div>
            </div>
            <br />
            <br className="hidden lg:block" />
            <div className="wrap-center block lg:flex lg:items-center lg:justify-center">
              <div className="w-full">
                <img className="w-img-bussiness float-left relative" alt='Produk-Produk Mingjaya' src={`${assets}/img-voila-biz.webp`} />
              </div>
              <div className="w-full py-8 lg:py-0">
                <p className="second-orange text-xl font-bold mb-6">voilabiz</p>
                <span className="block text-white text-sm lg:text-base pb-10">Setelah satu tahun berjalan, voilà.id menawarkan kesempatan bagi individu yang ingin mengembangkan bisnis di ranah luxury fashion mewah untuk bergabung sebagai reseller. 
                <br /><br />Pada Agustus 2022, platform voilà.Biz (voilá.id for business) resmi diluncurkan untuk memudahkan para reseller dalam melakukan transaksi.</span>
                <a href="https://biz.voila.id/" target="_blank" rel="noreferrer" className="rounded p-4 text-xs leading-5 text-black btn-sec-orange font-bold outline-none flex justify-center lg:inline opacity-90 hover:opacity-100">MULAI BELANJA</a>
              </div>
            </div>
          </div>
          <br />
          <br className="hidden lg:block" />
          <div className="wrap-center-full py-8 lg:py-16 hidden lg:block">
            <h3 className="second-orange text-2xl font-bold text-center mb-8 lg:mb-16">{items.brand.title}</h3>
            <div>
              <Slider {...settings}>
                {items.brand.data.map((brand, i) => {
                  return (
                    <a href={brand.path} rel="noreferrer" target="_blank" className="outline-none shadow-none relative" key={i}>
                      <div className="flex items-center justify-center h-12 md:h-20 lg:h-24 border-solid border-2 border-white border-opacity-10 rounded-lg mx-2 mb-4 bg-brand hover:opacity-80">
                        <div className="absolute inset-x-0 top-0">
                          <label className={`${label(brand.status_highlight).classLabel} rounded-b-3xl m-auto text-center text-xs lg:h-5 w-50 flex justify-center items-center`}>{label(brand.status_highlight).textLabel}</label>
                        </div>
                        <img className="w-16 md:w-24 lg:w-28 m-auto" src={brand.icon} alt={brand.title} />
                      </div>
                    </a>
                  )
                })}
              </Slider>
            </div>
          </div>
        </Element>
        <Element name="perjalan-kami" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <div className="wrap-center-full overflow-x-hidden">
            <h3 className="second-orange text-2xl font-bold text-center my-8 lg:my-16">{items.trip.title}</h3>
            <div className="hidden xl:block">
              <VerticalTimeline>
                {items.trip.data.map((trip, i) => {
                  return (
                    <VerticalTimelineElement
                      key={i}
                      className="vertical-timeline-element--work"
                      contentStyle={{ color: '#fff', backgroundImage: "url(" + trip.image + ")", backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundColor: 'transparent' }}
                      contentArrowStyle={{ borderRight: '1px solid  rgb(33, 150, 243)' }}
                      date={trip.year}
                      style={{ width: '100%'}}
                    >
                      <span className={trip.year ? "trip-title block pt-12 second-orange font-bold" : "trip-title block second-orange font-bold"}>{trip.title}</span>
                      <p className="trip-history">{trip.history}</p>
                    </VerticalTimelineElement>
                  )
                })}
              </VerticalTimeline>
            </div>
            <div className="block xl:hidden overflow-x-hidden">
              {items.trip.data.map((trip, i) => {
                return (
                  <div className="text-center" key={i}>
                    <p className="text-white opacity-50 font-bold leading-10 f-55 py-6">{trip.year}</p>
                    <img src={trip.image} alt={trip.title} className="w-full" />
                    <span className="block pt-12 pb-6 second-orange font-bold w-4/5 m-auto text-2xl">{trip.title}</span>
                    <p className="trip-history pb-12 text-white f-13 w-4/5 m-auto">{trip.history}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="overflow-x-hidden offline-store relative bg-no-repeat bg-cover flex items-center bg-center bg-fixed" style={{backgroundImage: `url(${assets}/bg-offline-store-jamtangan.webp?v=1)`}}>
            <div className="wrap-center-full">
              <div className="text-white block lg:flex lg:flex-row-reverse lg:items-center lg:justify-center">
                <div className="lg:w-2/5 w-full px-5 pt-8 lg:pl-10 lg:py-16">
                  <p className="second-orange text-2xl font-bold lg:mb-6">{items.store.jamtangan.title}</p>
                  <span className="block pt-4 text-base lg:w-96">{items.store.jamtangan.text}</span>
                  <span className="block pt-4 text-base lg:w-96">{items.store.jamtangan.text2}</span>
                </div>
                <div className="relative w-3/5 hidden lg:block">
                  <Slider {...store}>
                    {items.store.jamtangan.data.map((store, i) => {
                      return (
                        <div className="outline-none" key={i}>
                          <div className="m-2 border-solid lg:w-80 border-2 border-white border-opacity-10 rounded-lg focus:outline-none">
                            <img className="lg:w-80 h-auto rounded-t-lg" src={store.thumb} alt={store.title} />
                            <div className="p-4 mh-store opacity-87">
                              <p className="second-orange font-bold pb-4">{store.title}</p>
                              <span className="block f-13 opacity-87">{store.mall}</span>
                              <span className="block f-13 opacity-87">{store.address}</span>
                              <span className="block f-13 opacity-87 pb-4">{store.kota}</span>
                              <span className="block f-13 opacity-87">{store.phone}</span>
                              <span className="block f-13 opacity-87">{store.hour}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
                <div className="block lg:hidden flex overflow-auto pb-4">
                  {items.store.jamtangan.data.map((store, i) => {
                    return (
                      <div className="outline-none content-list" key={i}>
                        <div className="lg:m-2 border-solid lg:w-80 border-2 border-white border-opacity-10 rounded-lg focus:outline-none">
                          <img className="w-64 lg:w-80 h-auto rounded-t-lg" src={store.thumb} alt={store.title} />
                          <div className="p-4 mh-store opacity-87">
                            <p className="second-orange font-bold pb-4">{store.title}</p>
                            <span className="block f-13 opacity-87">{store.mall}</span>
                            <span className="block f-13 opacity-87">{store.address}</span>
                            <span className="block f-13 opacity-87 pb-4">{store.kota}</span>
                            <span className="block f-13 opacity-87">{store.phone}</span>
                            <span className="block f-13 opacity-87">{store.hour}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-hidden offline-store relative bg-no-repeat bg-cover flex items-center bg-center bg-fixed" style={{backgroundImage: `url(${assets}/bg-offline-store-voila.webp?v=1)`}}>
            <div className="wrap-center-full">
              <div className="text-white block  lg:flex lg:items-center lg:justify-center">
                <div className="lg:w-2/5 w-full px-5 pt-8 lg:px-0 lg:py-16">
                  <p className="second-orange text-2xl font-bold mb-0 lg:mb-6">{items.store.voila.title}</p>
                  <span className="block pt-4 text-base lg:w-96">{items.store.voila.text}</span>
                  <span className="block pt-4 text-base lg:w-96">{items.store.voila.text2}</span>
                </div>
                <div className="relative w-3/5 hidden lg:block">
                  <Slider {...store}>
                    {items.store.voila.data.map((store, i) => {
                      return (
                        <div className="outline-none" key={i}>
                          <div className="m-2 border-solid lg:w-80 border-2 border-white border-opacity-10 rounded-lg focus:outline-none">
                            <img className="lg:w-80 h-auto rounded-t-lg" src={store.thumb} alt={store.title} />
                            <div className="p-4 mh-store opacity-87">
                              <p className="second-orange font-bold pb-4">{store.title}</p>
                              <span className="block f-13 opacity-87">{store.mall}</span>
                              <span className="block f-13 opacity-87">{store.address}</span>
                              <span className="block f-13 opacity-87 pb-4">{store.kota}</span>
                              <span className="block f-13 opacity-87">{store.phone}</span>
                              <span className="block f-13 opacity-87">{store.hour}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
                <div className="block lg:hidden flex overflow-auto pb-4">
                  {items.store.voila.data.map((store, i) => {
                    return (
                      <div className="outline-none content-list" key={i}>
                        <div className="m-2 border-solid lg:w-80 border-2 border-white border-opacity-10 rounded-lg focus:outline-none">
                          <img className="w-64 lg:w-80 h-auto rounded-t-lg" src={store.thumb} alt={store.title} />
                          <div className="p-4 mh-store opacity-87">
                            <p className="second-orange font-bold pb-4">{store.title}</p>
                            <span className="block f-13 opacity-87">{store.mall}</span>
                            <span className="block f-13 opacity-87">{store.address}</span>
                            <span className="block f-13 opacity-87 pb-4">{store.kota}</span>
                            <span className="block f-13 opacity-87">{store.phone}</span>
                            <span className="block f-13 opacity-87">{store.hour}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </Element>
        <Element name="karir" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <div className="overflow-x-hidden bg-career pt-10">
            <div className="wrap-center block lg:flex lg:items-center lg:justify-center lg:flex-row-reverse py-16">
              <div className="w-full">
                <img className="w-img-career float-right" alt="Berkarir di Ming Jaya" src={`${assets}/karir.png`} />
              </div>
              <div className="w-full pt-96 lg:pt-0">
                <p className="second-orange text-2xl font-bold mb-6">Karir</p>
                <span className="block text-white text-sm lg:text-base w-text-career pb-10">Inovasi dan kreativitas menjadi elemen penting dalam membangun bisnis kami. Hal ini juga yang memungkinkan kami mampu untuk membantu customer kami menemukan produk fashion dan lifestyle yang sesuai dengan kriteria dan kebutuhan mereka.</span>
                <a href="https://career.ctlyst.id/jobs" rel="noreferrer" target="_blank" className="rounded p-4 text-xs leading-5 text-black btn-sec-orange font-bold outline-none flex justify-center lg:inline opacity-90 hover:opacity-100">BERGABUNG BERSAMA KAMI</a>
              </div>
            </div>
          </div>
        </Element>
        <Footer></Footer>
      </>
      :
      <div className="loader absolute z-10 top-2/4 left-2/4 rounded-full"></div>
    }
    </Layout>
  );
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
