import React from "react"

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="text-center">
      <div className="bg-grey-2">
        <span className="block text-center text-white opacity-87 f-10 py-3">
          Copyright © {year} PT. MING JAYA SEJAHTERA, All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}

export default Footer
